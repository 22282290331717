@charset "utf-8";
@import "../../node_modules/bulma/sass/utilities/_all";
@import "vars";

.navbar-burger:hover {
  color: $dominante;
}

//General
$background: $black;

//Footer
$footer-background-color: $black;

hr.is-tonico {
  background-color: $tonico;
}

//New classes
@media (min-width: $tablet) {
  .show-only-mobile {
    display: none !important
  }
}

//Don't show selection color
::selection { 
  background: transparent;
}

$addColors: (
  "tonico": ($tonico, $tonico-invert),
  "dominante": ($dominante, $dominante-invert)
);
$colors: map-merge($colors, $addColors);

@import "../../node_modules/bulma";

.supidc { 
  vertical-align: super;
  font-size: 12pt;
}

.navbar-brand .navbar-item {
  padding-left: 32px;
}

html {
  background-color: $black;
}
.has-background-light-transparent {
  background-color: rgba( $white, .7 );
}

body{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.image.is-min-250x250 {
  min-height: 250px;
  min-width: 250px;
}

main, .wrapper {
  flex: 1;
  display:flex;
  flex-direction: column;
}

strong {
  color: white;
}

.footer{
  height: 3rem;
  padding: 0 1rem;
  a {
    color: rgb(255 203 144 / 70%);
    &:hover {
      color: $tonico;
    }
  }
  strong {
    color: rgb(236 220 201 / 70%);
  }
  &:hover{
   strong {
     color: $tonico;
   }
  }
  .level {
    height: 100%;
    margin: auto;
  }
}

.lema {
  margin: auto 5px;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.2);

  .title {
    text-shadow: 3px 3px 4px $black;
  }

  .subtitle {
    text-shadow: 1px 1px 1px $black;
  }
}

#dyn-bg {
  --vanta-background-color: 0x0a0a0a;
  --vanta-color: 0xF68A10;
}

#menu {
  position:absolute;
  width: 100%;
}

.feder-logos {
  display: none;
  .ivace {
    margin: -40px;
  }
}

a:hover .feder-logos {
  display: flex;
  position: absolute;
  left: 20px;
  top: -110px;
  background-color: rgba(255, 255, 255, 0.5);
}