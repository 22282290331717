@import "../../node_modules/bulma/sass/utilities/_all";
//Colors
$dominante: #424242;
$dominante-invert: findColorInvert($dominante);

$tonico: #F68A10;
$tonico-invert: findColorInvert($tonico);

$mediante-start: #F884C5;
$mediante-start-invert: findColorInvert($mediante-start);
$mediante-end: #7B6DF8;
$mediante-end-invert: findColorInvert($mediante-end);

//Navbar settings
$navbar-breakpoint: 99999999px; //HACK: Prevent hiding burger on desktop
$navbar-height : 8rem;
$navbar-item-img-max-height: 7rem;
$navbar-background-color: $black;
$navbar-burger-color: $dominante-invert;